// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-bigdiscountdays-tnc-index-js": () => import("./../../../src/pages/bigdiscountdays/tnc/index.js" /* webpackChunkName: "component---src-pages-bigdiscountdays-tnc-index-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-cashback-terms-conditions-js": () => import("./../../../src/pages/cashback-terms-conditions.js" /* webpackChunkName: "component---src-pages-cashback-terms-conditions-js" */),
  "component---src-pages-communications-js": () => import("./../../../src/pages/communications.js" /* webpackChunkName: "component---src-pages-communications-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-ecommerce-js": () => import("./../../../src/pages/ecommerce.js" /* webpackChunkName: "component---src-pages-ecommerce-js" */),
  "component---src-pages-faq-solv-score-js": () => import("./../../../src/pages/faq/solv-score.js" /* webpackChunkName: "component---src-pages-faq-solv-score-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-information-security-policy-js": () => import("./../../../src/pages/information-security-policy.js" /* webpackChunkName: "component---src-pages-information-security-policy-js" */),
  "component---src-pages-media-index-js": () => import("./../../../src/pages/media/index.js" /* webpackChunkName: "component---src-pages-media-index-js" */),
  "component---src-pages-media-press-js": () => import("./../../../src/pages/media/press.js" /* webpackChunkName: "component---src-pages-media-press-js" */),
  "component---src-pages-partners-shopmatic-index-js": () => import("./../../../src/pages/partners/shopmatic/index.js" /* webpackChunkName: "component---src-pages-partners-shopmatic-index-js" */),
  "component---src-pages-posh-policy-js": () => import("./../../../src/pages/posh-policy.js" /* webpackChunkName: "component---src-pages-posh-policy-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-promos-april-offer-2024-tnc-index-js": () => import("./../../../src/pages/promos/april-offer-2024/tnc/index.js" /* webpackChunkName: "component---src-pages-promos-april-offer-2024-tnc-index-js" */),
  "component---src-pages-promos-apriloffers-tnc-index-js": () => import("./../../../src/pages/promos/apriloffers/tnc/index.js" /* webpackChunkName: "component---src-pages-promos-apriloffers-tnc-index-js" */),
  "component---src-pages-promos-august-offer-2024-tnc-index-jsx": () => import("./../../../src/pages/promos/august-offer-2024/tnc/index.jsx" /* webpackChunkName: "component---src-pages-promos-august-offer-2024-tnc-index-jsx" */),
  "component---src-pages-promos-august-offers-tnc-index-js": () => import("./../../../src/pages/promos/augustOffers/tnc/index.js" /* webpackChunkName: "component---src-pages-promos-august-offers-tnc-index-js" */),
  "component---src-pages-promos-bigdiscountdays-tnc-offer-2-index-js": () => import("./../../../src/pages/promos/bigdiscountdays/tnc-offer2/index.js" /* webpackChunkName: "component---src-pages-promos-bigdiscountdays-tnc-offer-2-index-js" */),
  "component---src-pages-promos-bigdiscountdays-tnc-offer-3-index-js": () => import("./../../../src/pages/promos/bigdiscountdays/tnc-offer3/index.js" /* webpackChunkName: "component---src-pages-promos-bigdiscountdays-tnc-offer-3-index-js" */),
  "component---src-pages-promos-decemberoffers-tnc-index-js": () => import("./../../../src/pages/promos/decemberoffers/tnc/index.js" /* webpackChunkName: "component---src-pages-promos-decemberoffers-tnc-index-js" */),
  "component---src-pages-promos-delhi-ncr-offer-tnc-index-js": () => import("./../../../src/pages/promos/delhi-ncr-offer/tnc/index.js" /* webpackChunkName: "component---src-pages-promos-delhi-ncr-offer-tnc-index-js" */),
  "component---src-pages-promos-februaryoffers-tnc-index-js": () => import("./../../../src/pages/promos/februaryoffers/tnc/index.js" /* webpackChunkName: "component---src-pages-promos-februaryoffers-tnc-index-js" */),
  "component---src-pages-promos-festiveoffer-tnc-index-js": () => import("./../../../src/pages/promos/festiveoffer/tnc/index.js" /* webpackChunkName: "component---src-pages-promos-festiveoffer-tnc-index-js" */),
  "component---src-pages-promos-fmcg-horeca-offer-tnc-index-js": () => import("./../../../src/pages/promos/fmcg-horeca-offer/tnc/index.js" /* webpackChunkName: "component---src-pages-promos-fmcg-horeca-offer-tnc-index-js" */),
  "component---src-pages-promos-januaryoffers-tnc-index-js": () => import("./../../../src/pages/promos/januaryoffers/tnc/index.js" /* webpackChunkName: "component---src-pages-promos-januaryoffers-tnc-index-js" */),
  "component---src-pages-promos-july-offer-2024-tnc-index-js": () => import("./../../../src/pages/promos/july-offer-2024/tnc/index.js" /* webpackChunkName: "component---src-pages-promos-july-offer-2024-tnc-index-js" */),
  "component---src-pages-promos-july-offer-3-tnc-index-js": () => import("./../../../src/pages/promos/july-offer-3/tnc/index.js" /* webpackChunkName: "component---src-pages-promos-july-offer-3-tnc-index-js" */),
  "component---src-pages-promos-julyoffer-tnc-index-js": () => import("./../../../src/pages/promos/julyoffer/tnc/index.js" /* webpackChunkName: "component---src-pages-promos-julyoffer-tnc-index-js" */),
  "component---src-pages-promos-julyoffers-tnc-index-js": () => import("./../../../src/pages/promos/julyoffers/tnc/index.js" /* webpackChunkName: "component---src-pages-promos-julyoffers-tnc-index-js" */),
  "component---src-pages-promos-june-offer-2023-tnc-index-js": () => import("./../../../src/pages/promos/juneOffer2023/tnc/index.js" /* webpackChunkName: "component---src-pages-promos-june-offer-2023-tnc-index-js" */),
  "component---src-pages-promos-june-offer-2024-tnc-index-js": () => import("./../../../src/pages/promos/june-offer-2024/tnc/index.js" /* webpackChunkName: "component---src-pages-promos-june-offer-2024-tnc-index-js" */),
  "component---src-pages-promos-juneoffers-tnc-index-js": () => import("./../../../src/pages/promos/juneoffers/tnc/index.js" /* webpackChunkName: "component---src-pages-promos-juneoffers-tnc-index-js" */),
  "component---src-pages-promos-marchoffers-tnc-index-js": () => import("./../../../src/pages/promos/marchoffers/tnc/index.js" /* webpackChunkName: "component---src-pages-promos-marchoffers-tnc-index-js" */),
  "component---src-pages-promos-may-offer-2024-tnc-index-js": () => import("./../../../src/pages/promos/may-offer-2024/tnc/index.js" /* webpackChunkName: "component---src-pages-promos-may-offer-2024-tnc-index-js" */),
  "component---src-pages-promos-mayoffers-tnc-index-js": () => import("./../../../src/pages/promos/mayoffers/tnc/index.js" /* webpackChunkName: "component---src-pages-promos-mayoffers-tnc-index-js" */),
  "component---src-pages-promos-november-offer-2023-tnc-index-js": () => import("./../../../src/pages/promos/november-offer-2023/tnc/index.js" /* webpackChunkName: "component---src-pages-promos-november-offer-2023-tnc-index-js" */),
  "component---src-pages-promos-novemberoffers-tnc-index-js": () => import("./../../../src/pages/promos/novemberoffers/tnc/index.js" /* webpackChunkName: "component---src-pages-promos-novemberoffers-tnc-index-js" */),
  "component---src-pages-promos-octoberoffers-tnc-index-js": () => import("./../../../src/pages/promos/octoberoffers/tnc/index.js" /* webpackChunkName: "component---src-pages-promos-octoberoffers-tnc-index-js" */),
  "component---src-pages-promos-ramadanfestiveoffer-tnc-index-js": () => import("./../../../src/pages/promos/ramadanfestiveoffer/tnc/index.js" /* webpackChunkName: "component---src-pages-promos-ramadanfestiveoffer-tnc-index-js" */),
  "component---src-pages-promos-september-offer-2024-tnc-index-js": () => import("./../../../src/pages/promos/september-offer-2024/tnc/index.js" /* webpackChunkName: "component---src-pages-promos-september-offer-2024-tnc-index-js" */),
  "component---src-pages-promos-septemberoffers-tnc-index-js": () => import("./../../../src/pages/promos/septemberoffers/tnc/index.js" /* webpackChunkName: "component---src-pages-promos-septemberoffers-tnc-index-js" */),
  "component---src-pages-promos-smedy-offer-tnc-index-js": () => import("./../../../src/pages/promos/smedy-offer/tnc/index.js" /* webpackChunkName: "component---src-pages-promos-smedy-offer-tnc-index-js" */),
  "component---src-pages-promos-solv-dhamaka-tnc-index-js": () => import("./../../../src/pages/promos/solv-dhamaka/tnc/index.js" /* webpackChunkName: "component---src-pages-promos-solv-dhamaka-tnc-index-js" */),
  "component---src-pages-promos-solv-offer-tnc-index-js": () => import("./../../../src/pages/promos/solv-offer/tnc/index.js" /* webpackChunkName: "component---src-pages-promos-solv-offer-tnc-index-js" */),
  "component---src-pages-promos-solvsureactiveoffer-tnc-index-js": () => import("./../../../src/pages/promos/solvsureactiveoffer/tnc/index.js" /* webpackChunkName: "component---src-pages-promos-solvsureactiveoffer-tnc-index-js" */),
  "component---src-pages-promos-supersaver-tnc-index-js": () => import("./../../../src/pages/promos/supersaver/tnc/index.js" /* webpackChunkName: "component---src-pages-promos-supersaver-tnc-index-js" */),
  "component---src-pages-referral-tnc-js": () => import("./../../../src/pages/referral-tnc.js" /* webpackChunkName: "component---src-pages-referral-tnc-js" */),
  "component---src-pages-return-shipment-policy-js": () => import("./../../../src/pages/return-shipment-policy.js" /* webpackChunkName: "component---src-pages-return-shipment-policy-js" */),
  "component---src-pages-services-business-services-js": () => import("./../../../src/pages/services/business-services.js" /* webpackChunkName: "component---src-pages-services-business-services-js" */),
  "component---src-pages-services-financial-services-js": () => import("./../../../src/pages/services/financial-services.js" /* webpackChunkName: "component---src-pages-services-financial-services-js" */),
  "component---src-pages-smedy-offer-tnc-index-js": () => import("./../../../src/pages/smedy-offer/tnc/index.js" /* webpackChunkName: "component---src-pages-smedy-offer-tnc-index-js" */),
  "component---src-pages-sms-read-terms-and-condition-js": () => import("./../../../src/pages/sms-read-terms-and-condition.js" /* webpackChunkName: "component---src-pages-sms-read-terms-and-condition-js" */),
  "component---src-pages-solv-sure-active-js": () => import("./../../../src/pages/solv-sure-active.js" /* webpackChunkName: "component---src-pages-solv-sure-active-js" */),
  "component---src-pages-terms-of-services-js": () => import("./../../../src/pages/terms-of-services.js" /* webpackChunkName: "component---src-pages-terms-of-services-js" */),
  "component---src-templates-press-article-js": () => import("./../../../src/templates/press-article.js" /* webpackChunkName: "component---src-templates-press-article-js" */)
}

